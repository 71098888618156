import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import { logger } from '@nx/utils/logger'

import { TypesenseClientInstance } from '../typesenseClient'
import {
  GetTypesenseAuctionsParams,
  getTypesenseAuctions,
} from './getTypesenseAuctions'

type UseGetTypesenseAuctionsProps = {
  client: TypesenseClientInstance
  auctionsPerPage: number
  enabled?: boolean
  searchParams: GetTypesenseAuctionsParams & { startingPage: number }
}

export function useGetTypesenseAuctions({
  client,
  auctionsPerPage,
  enabled = true,
  searchParams,
}: UseGetTypesenseAuctionsProps) {
  const { startingPage: initialPageParam, ...getTypesenseAuctionsParams } =
    searchParams

  const result = useInfiniteQuery({
    queryKey: ['auctions', searchParams],
    queryFn: ({ pageParam: page }) =>
      getTypesenseAuctions(client)({
        ...getTypesenseAuctionsParams,
        page,
        per_page: auctionsPerPage,
      }),
    getNextPageParam: ({ page, nbPages }) => (page < nbPages ? page + 1 : page),
    staleTime: 0,
    initialPageParam,
    placeholderData: keepPreviousData,
    retry: 3,
    retryDelay: 1000,
    enabled,
  })

  if (result.isError) {
    logger.error(
      result.error,
      `auctions collection - auctionIds: ${JSON.stringify(getTypesenseAuctionsParams)}`
    )
  }

  return result
}
