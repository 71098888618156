import { logger } from '@nx/utils/logger'

import { getTypesenseLots } from './getTypesenseLots'

export const getTypesenseLotsByIDs = async (
  typesenseConfig: Parameters<typeof getTypesenseLots>[0],
  lotUniqueIds: (string | number)[]
) => {
  try {
    const response = await getTypesenseLots(typesenseConfig)({
      brand: null,
      filter_by: `lotUniqueId:[${lotUniqueIds.join(',')}]`,
    })

    const hitsById = new Map(
      response.hits.map((hit) => [String(hit.lotUniqueId), hit])
    )

    return lotUniqueIds.map((id) => hitsById.get(String(id)) || null)
  } catch (error) {
    logger.error(
      error,
      `lot-details collection - lotUniqueIds: ${lotUniqueIds}`
    )

    return []
  }
}
