import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import { DateValue, ImageValue } from '@bonhams1793/bonhams-typescript'

import { TypesenseClientInstance } from '../typesenseClient'
import { extractDocuments } from '../typesenseHelpers'

export interface TypesenseHeadline {
  id: string
  title: string
  description: string
  derived_category: string
  image?: ImageValue
  slug: string
  shorthandUrl: string
  published: DateValue
}

export interface GetTypesenseHeadlinesParams extends SearchParams {
  perPage?: MultiSearchRequestSchema['per_page']
  page?: MultiSearchRequestSchema['page']
  sortBy?: MultiSearchRequestSchema['sort_by']
  derivedCategories?: Array<
    'Magazine' | 'Collecting Guides' | 'Auction Highlights'
  >
  categories?: Array<'STORIES' | 'MAGAZINE' | 'NEWS'>
}

export type TypesenseHeadlineSearchResponse = Omit<
  SearchResponse<TypesenseHeadline>,
  'hits'
> & {
  hits: TypesenseHeadline[]
}

export function getTypesenseHeadlines({
  typesenseRequest,
}: TypesenseClientInstance) {
  return async ({
    perPage = 14,
    page = 1,
    sortBy = 'published.timestamp:desc',
    derivedCategories,
    categories,
    filter_by = '',
  }: GetTypesenseHeadlinesParams = {}): Promise<TypesenseHeadlineSearchResponse> => {
    const filters = []

    if (categories) {
      filters.push(`category:[${categories.join(',')}]`)
    }

    if (derivedCategories) {
      filters.push(`derived_category:[${derivedCategories.join(',')}]`)
    }

    if (filter_by) {
      filters.push(filter_by)
    }

    const mainResponse = await typesenseRequest<
      SearchResponse<TypesenseHeadline>
    >('headlines', {
      include_fields:
        'id, title, description, derived_category, image, published, slug, shorthandUrl',
      query_by: '',
      q: '*',
      filter_by: filters.join(' && '),
      facet_by: 'derived_category',
      page,
      per_page: perPage,
      sort_by: sortBy,
    })
    return {
      ...extractDocuments(mainResponse),
    }
  }
}
