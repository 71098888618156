import { SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import { TypesenseClientInstance } from '../typesenseClient'
import { extractDocuments } from '../typesenseHelpers'
import { TypesenseAuctionDetails } from './types'

export type GetTypesenseAuctionDetailsProps = {
  auctionId: string | number
}

export function getTypesenseAuctionDetails({
  typesenseRequest,
}: TypesenseClientInstance) {
  return async (filters: GetTypesenseAuctionDetailsProps) => {
    try {
      const searchParams: MultiSearchRequestSchema = {
        filter_by: `auctionId:=${filters.auctionId}`,
      }

      searchParams['query_by'] = 'auctionTitle'
      searchParams['q'] = ''

      const mainResponse = await typesenseRequest<
        SearchResponse<TypesenseAuctionDetails>
      >('auction-details', searchParams)

      return extractDocuments(mainResponse).hits[0]
    } catch {
      return null
    }
  }
}
