/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/nextjs'
import Typesense from 'typesense'
import {
  MultiSearchRequestSchema,
  MultiSearchRequestsSchema,
} from 'typesense/lib/Typesense/MultiSearch'

import { withResolversPolyfill } from '@nx/helpers'

type PromiseExecutor<T> = Omit<PromiseWithResolvers<T>, 'promise'>

type TypesenseQuery<T = any> = MultiSearchRequestsSchema['searches'][number] & {
  promise: PromiseExecutor<T>
}

withResolversPolyfill()

export interface TypesenseClientConfig {
  apiKey: string
  host: string
  port?: number
  protocol?: string
  path?: string
  connectionTimeoutSeconds?: number
}

export type TypesenseClientInstance = ReturnType<typeof typesenseClient>

export function typesenseClient({
  apiKey,
  host,
  port = 443,
  protocol = 'https',
  path = '/search',
  connectionTimeoutSeconds = /* istanbul ignore next */ 2,
}: TypesenseClientConfig) {
  let queries: TypesenseQuery[] = []
  let timeout: ReturnType<typeof setTimeout>

  const getTypesenseClient = () =>
    new Typesense.Client({
      nodes: [
        {
          host,
          port,
          protocol,
          path,
        },
      ],
      apiKey,
      connectionTimeoutSeconds,
    })

  const typesenseRequest = <
    TObject,
    I extends string = string,
    T extends MultiSearchRequestSchema = MultiSearchRequestSchema,
  >(
    collection: I,
    params: T,
    isCachedEnabled = true
  ) => {
    const { promise, ...executor } = Promise.withResolvers<TObject>()

    queries.push({
      collection,
      ...params,

      promise: executor,
    })

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      triggerMultipleQuery(isCachedEnabled)
    }, 0)

    return promise
  }
  const executeTypesenseQueries = (isCacheEnabled = true) => {
    if (timeout) {
      clearTimeout(timeout)
      triggerMultipleQuery(isCacheEnabled)
    }
  }

  const triggerMultipleQuery = (isCacheEnabled?: boolean) => {
    const tempQueries = [...queries]

    // Reset queries
    queries = []

    const parsedQueries = tempQueries.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ promise: _promise, ...rest }) => rest
    )

    if (tempQueries.length) {
      getTypesenseClient()
        .multiSearch.perform(
          { searches: parsedQueries },
          {
            use_cache: isCacheEnabled,
            enable_lazy_filter: true,
          }
        )
        .then(({ results }) => {
          tempQueries.forEach(({ promise }, index) => {
            const value = results.at(index)
            promise.resolve?.(value)
          })
        })
        .catch((error) => {
          Sentry.captureException(error)

          // Reject all queries if any of multi-query requests fail.
          tempQueries.forEach(({ promise }, index) => {
            // istanbul ignore next
            promise.reject?.({
              ...(typeof error === 'object' ? error : {}),
              queryParameters: parsedQueries[index],
            })
          })
        })
    }
  }

  return {
    getTypesenseClient,
    typesenseRequest,
    executeTypesenseQueries,
    triggerMultipleQuery,
  }
}
