import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import { LotStatus } from '@bonhams1793/bonhams-typescript'

import { AlgoliaLotIndices, TypesenseLot } from '@nx/global-types'

import { TypesenseClientInstance } from '../typesenseClient'
import {
  buildFilterString,
  extractDocuments,
  extractFacets,
  generateFacetsQueries,
} from '../typesenseHelpers'

export type TypesenseLotResponse = Omit<
  SearchResponse<TypesenseLot>,
  'hits'
> & {
  facets: Record<string, Record<string, number>>
  hits: TypesenseLot[]
  nbPages: number
  nbHits: number
}

export type GetTypesenseLotsParams = SearchParams & {
  query?: string
  collection?: 'lots'
  auctionId?: string
  minPrice?: number
  maxPrice?: number
  minGBPPrice?: number
  maxGBPPrice?: number
  departments?: string[]
  chronology?: 'past' | 'future'
  isWithoutReserve?: boolean
  excludeWithdrawn?: boolean
  excludeUnsold?: boolean
  country?: string[]
  brand?: string[] | null
  lotItemIds?: number[] | string[]
  groups?: string[]
  filters?: string
}

const FACET_BY = [
  'brand',
  'country.name',
  'country.code',
  'department.code',
  'department.name',
  'flags.isWithoutReserve',
  'price.GBPHighEstimate',
  'price.GBPLowEstimate',
  'price.estimateHigh',
  'price.estimateLow',
  'status',
  'groups',
]

export function getTypesenseLots({
  typesenseRequest,
}: TypesenseClientInstance) {
  return async ({
    collection = 'lots',
    auctionId,
    minPrice,
    maxPrice,
    minGBPPrice,
    maxGBPPrice,
    departments = [],
    chronology,
    excludeWithdrawn,
    excludeUnsold,
    country = [],
    brand = [],
    lotItemIds = [],
    groups = [],
    isWithoutReserve,
    filter_by = '',
    query_by = ['title'].join(','),
    sort_by = 'lotNo.number:asc,lotNo.letter:asc',
    page = 0,
    per_page = 24,
    query = '',
    ...searchArgs
  }: GetTypesenseLotsParams): Promise<TypesenseLotResponse> => {
    const facetFiltersRelation: Record<string, (string | number)[]> = {
      'country.name': [country].flat().filter(Boolean),
      'department.name': [departments].flat().filter(Boolean),
      brand: [brand].flat().filter(Boolean),
      groups: [groups].flat().filter(Boolean),
    }

    if (chronology) {
      facetFiltersRelation['status'] =
        chronology === 'future'
          ? [LotStatus.new]
          : [`${LotStatus.sold}`, `${LotStatus.withoutResults}`]
    }

    const filters = [
      buildFilterString('auctionId', auctionId ? `${auctionId}` : ''),
      buildFilterString(
        'flags.isWithoutReserve',
        isWithoutReserve ? 'true' : ''
      ),
      buildFilterString(
        'status',
        excludeWithdrawn
          ? {
              value: LotStatus.withdrawn,
              exclude: true,
            }
          : ''
      ),
      buildFilterString(
        'status',
        excludeUnsold
          ? {
              value: LotStatus.unsold,
              exclude: true,
            }
          : ''
      ),
      buildFilterString('lotItemId', lotItemIds),
      filter_by,
    ]

    const facetFilters = Object.entries(facetFiltersRelation).map(
      ([key, value]) => buildFilterString(key, value)
    )

    if (typeof minPrice !== 'undefined' && typeof maxPrice !== 'undefined') {
      filters.push(
        `(price.estimateLow:>=${minPrice} && price.estimateHigh:<=${maxPrice})`
      )
    }

    if (
      typeof minGBPPrice !== 'undefined' &&
      typeof maxGBPPrice !== 'undefined'
    ) {
      filters.push(
        `(price.GBPLowEstimate:>=${minGBPPrice} && price.GBPHighEstimate:<=${maxGBPPrice})`
      )
    }

    const finalFilters = [...filters, ...facetFilters]
      .filter(Boolean)
      .join(' && ')

    const mainParams: MultiSearchRequestSchema = {
      exclude_fields: ['footnotes', 'catalogDesc'].join(','),
      filter_by: filters.filter(Boolean).join(' && '),
      facet_by: FACET_BY.join(','),
      query_by,
      sort_by,
      page: Math.max(1, page),
      per_page,
      max_facet_values: 300,
      q: query,
      ...searchArgs,
    }

    const facetQueries = generateFacetsQueries(facetFiltersRelation, mainParams)

    // We want to disable TS cache when we're sorting by "Available to bid".
    const isCacheEnabled = !sort_by.includes('flags.isToBeSold:desc')
    const mainResponse = typesenseRequest<SearchResponse<TypesenseLot>>(
      collection,
      { ...mainParams, filter_by: finalFilters },
      isCacheEnabled
    )

    const facetQueryResponses = facetQueries.map((params) =>
      typesenseRequest<SearchResponse<TypesenseLot>>(
        collection,
        params,
        isCacheEnabled
      )
    )

    const [mainResult, ...extraResults] = await Promise.all([
      mainResponse,
      ...facetQueryResponses,
    ])

    const facetCounts = [
      ...(mainResult.facet_counts ?? []),
      ...extraResults.flatMap((extra) => extra.facet_counts),
    ].filter(Boolean)

    return {
      ...extractDocuments(mainResult),
      facets: extractFacets(facetCounts),
      facet_counts: facetCounts,
      nbPages: Math.ceil(mainResult.found / per_page) || 0,
      nbHits: mainResult.found,
    }
  }
}

export const mapAlgoliaIndexToTypesenseSortBy = (
  algoliaIndex: AlgoliaLotIndices | undefined
) => {
  const sortByMapping: Record<AlgoliaLotIndices, string> = {
    lots: '',
    lots_virtual_sort_lotno_desc: 'lotNo.number:desc,lotNo.letter:asc',
    lots_virtual_sort_lotno_asc: 'lotNo.number:asc,lotNo.letter:asc',
    lots_virtual_sort_price_desc:
      'price.hammerPremium:desc,price.estimateLow:desc',
    lots_virtual_sort_price_asc:
      'price.hammerPremium:asc,price.estimateLow:asc',
    lots_virtual_sort_to_be_sold_desc:
      'flags.isToBeSold:desc,lotNo.number:asc,lotNo.letter:asc',
    lots_virtual_sort_hammertime_desc: 'hammerTime.timestamp:desc',
    lots_virtual_sort_hammertime_asc: 'hammerTime.timestamp:asc',
    lots_virtual_sort_gbp_price_asc: 'price.GBPLowEstimate:asc',
    lots_virtual_sort_gbp_price_desc: 'price.GBPLowEstimate:desc',
  }

  return algoliaIndex
    ? sortByMapping[algoliaIndex]
    : 'lotNo.number:asc,lotNo.letter:asc'
}
