import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import {
  AuctionType,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
  EBrand,
} from '@bonhams1793/bonhams-typescript'

import { getDateNowUtc } from '@nx/dates'
import { daysToSeconds } from '@nx/helpers'

import { TypesenseClientInstance } from '../typesenseClient'
import {
  TypesenseFilter,
  buildFilterString,
  extractDocuments,
  extractFacets,
  generateFacetsQueries,
} from '../typesenseHelpers'
import { TypesenseAuction } from './types'

export type TypesenseAuctionResponse = Omit<
  SearchResponse<TypesenseAuction>,
  'hits'
> & {
  facets: Record<string, Record<string, number>>
  hits: TypesenseAuction[]
  nbPages: number
}

export type GetTypesenseAuctionsParams = SearchParams & {
  collection?: 'auctions-search'
  locationCodes?: string[]
  category?: string | string[]
  department?: string | string[]
  categoryCode?: string | string[]
  departmentCode?: string | string[]
  regionCode?: string | string[]
  country?: string | string[]
  monthAndYear?: string | string[]
  daysFromNow?: number
  futureOnly?: boolean
  auctionStatus?:
    | EAuctionStatus
    | TypesenseFilter<EAuctionStatus>
    | (TypesenseFilter<EAuctionStatus> | EAuctionStatus)[]
  auctionType?: EAuctionType | EAuctionType[]
  biddingStatus?:
    | EAuctionBiddingStatus
    | TypesenseFilter<EAuctionBiddingStatus>
    | TypesenseFilter<EAuctionBiddingStatus>[]
  brand:
    | EBrand
    | EBrand[]
    | TypesenseFilter<EBrand>
    | TypesenseFilter<EBrand>[]
    | null
}

const FACET_BY = [
  'auctionType',
  'monthAndYear',
  'departments.name',
  'country.name',
  'categories.name',
  'region.code',
]

export function getTypesenseAuctions({
  typesenseRequest,
}: TypesenseClientInstance) {
  return async ({
    collection = 'auctions-search',
    locationCodes = [],
    category = [],
    department = [],
    categoryCode = [],
    departmentCode = [],
    regionCode = [],
    country = [],
    monthAndYear = [],
    daysFromNow,
    auctionStatus = [],
    auctionType = ['ONLINE', 'PUBLIC'],
    biddingStatus = [],
    brand,
    futureOnly = false,
    filter_by = '',
    query_by = ['auctionHeading', 'auctionTitle', 'departments.name'].join(','),
    sort_by = 'hammerTime.timestamp:desc,auctionTitle:desc',
    page = 1,
    per_page = 24,
    q = '',
    ...searchArgs
  }: GetTypesenseAuctionsParams): Promise<TypesenseAuctionResponse> => {
    if (Array.isArray(auctionType) && !auctionType.length) {
      auctionType = ['ONLINE', 'PUBLIC']
    }

    const facetFiltersRelation: Record<string, string[]> = {
      'categories.name': [category].flat().filter(Boolean),
      'country.name': [country].flat().filter(Boolean),
      'departments.name': [department].flat().filter(Boolean),
      monthAndYear: [monthAndYear].flat().filter(Boolean),
      auctionType: [auctionType as string].flat().filter(Boolean),
    }

    const filters = [
      buildFilterString('auctionStatus', auctionStatus),
      buildFilterString('departments.code', departmentCode),
      buildFilterString('region.code', regionCode),
      buildFilterString('categories.code', categoryCode),
      buildFilterString('biddingStatus', biddingStatus),
      buildFilterString('brand', brand),
      buildFilterString('venue.code', locationCodes),
      filter_by,
    ]

    const facetFilters = Object.entries(facetFiltersRelation).map(
      ([key, value]) => buildFilterString(key, value)
    )

    if (futureOnly) {
      let now = getDateNowUtc().seconds

      const tempType = Array.isArray(auctionType) ? auctionType : [auctionType]
      if (tempType.includes(AuctionType.online)) {
        // We're adding additional 20 hours to give some leeway for auctions with end date
        // in the past, but still going because of staggered lot ending times.
        const twentyHoursInSeconds = 20 * 3600
        now = now - twentyHoursInSeconds
      }

      const futureOnlyFilter = `dates.end.timestamp:>${now}`
      filters.push(futureOnlyFilter)
    }

    if (daysFromNow) {
      const now = getDateNowUtc().seconds
      filters.push(
        `dates.end.timestamp:>=${now} && dates.end.timestamp:<=${now + daysToSeconds(daysFromNow)}`
      )
    }

    const excludeFields = ['description']

    const finalFilters = [...filters, ...facetFilters]
      .filter(Boolean)
      .join(' && ')

    const mainParams: MultiSearchRequestSchema = {
      exclude_fields: excludeFields.join(','),
      filter_by: filters.filter(Boolean).join(' && '),
      facet_by: FACET_BY.join(','),
      query_by,
      sort_by,
      page: Math.max(1, page),
      per_page,
      max_facet_values: 300,
      q,
      ...searchArgs,
    }

    const facetQueries = generateFacetsQueries(facetFiltersRelation, mainParams)

    const mainResponse = typesenseRequest<SearchResponse<TypesenseAuction>>(
      collection,
      { ...mainParams, filter_by: finalFilters }
    )

    const facetQueryResponses = facetQueries.map((params) =>
      typesenseRequest<SearchResponse<TypesenseAuction>>(collection, params)
    )

    const [mainResult, ...extraResults] = await Promise.all([
      mainResponse,
      ...facetQueryResponses,
    ])

    const facetCounts = [
      ...(mainResult.facet_counts ?? []),
      ...extraResults.flatMap((extra) => extra.facet_counts),
    ].filter(Boolean)

    return {
      ...extractDocuments(mainResult),
      facets: extractFacets(facetCounts),
      facet_counts: facetCounts,
      nbPages: Math.ceil(mainResult.found / per_page) || 0,
    }
  }
}
