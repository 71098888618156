import { logger } from '@nx/utils/logger'

import { getTypesenseHeadlines } from './getTypesenseHeadlines'

export const getTypesenseHeadlinesByIDs = async (
  typesenseConfig: Parameters<typeof getTypesenseHeadlines>[0],
  headlineIds: (string | number)[]
) => {
  try {
    const response = await getTypesenseHeadlines(typesenseConfig)({
      filter_by: `id:[${headlineIds.join(',')}]`,
    })

    const hitsById = new Map(response.hits.map((hit) => [String(hit.id), hit]))

    return headlineIds.map((id) => hitsById.get(String(id))).filter(Boolean)
  } catch (error) {
    logger.error(error, `headlines collection - headlineIds: ${headlineIds}`)

    return []
  }
}
