import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/react-query'

import { logger } from '@nx/utils/logger'

import { TypesenseClientInstance } from '../typesenseClient'
import {
  GetTypesenseHeadlinesParams,
  TypesenseHeadlineSearchResponse,
  getTypesenseHeadlines,
} from './getTypesenseHeadlines'

interface UseGetTypesenseHeadlinesParams extends GetTypesenseHeadlinesParams {
  client: TypesenseClientInstance
  name?: string
  startingPage?: number
  enabled?: boolean
  perPage: number
  initialData?: InfiniteData<
    TypesenseHeadlineSearchResponse,
    number | undefined
  >
}

export const useGetTypesenseHeadlines = ({
  client,
  name = 'storyHeadlines',
  startingPage,
  enabled = true,
  initialData,
  perPage,
  ...params
}: UseGetTypesenseHeadlinesParams) => {
  const refetchOnWindowFocus = false

  const result = useInfiniteQuery({
    queryKey: [name, { params }],
    queryFn: ({ pageParam: page }) =>
      getTypesenseHeadlines(client)({
        page,
        perPage,
        ...params,
      }),
    initialPageParam: startingPage,
    getNextPageParam: ({ page, found }) =>
      page * perPage >= found ? undefined : page + 1,
    getPreviousPageParam: ({ page }) => (page > 1 ? page - 1 : undefined),
    staleTime: 1000,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus,
    enabled,
    retry: 3,
    retryDelay: 1000,
    initialData,
  })

  if (result.isError) {
    logger.error(
      result.error,
      `headlines collection - ${JSON.stringify(params)}`
    )
  }

  return result
}
