import {
  getTypesenseAuctionDetails as getTypesenseAuctionDetailsLib,
  getTypesenseAuctionsByIDs as getTypesenseAuctionsByIDsLib,
  getTypesenseAuctions as getTypesenseAuctionsLib,
  getTypesenseHeadlinesByIDs as getTypesenseHeadlinesByIDsLib,
  getTypesenseHeadlines as getTypesenseHeadlinesLib,
  getTypesenseLotDetails as getTypesenseLotDetailsLib,
  getTypesenseLotsByIDs as getTypesenseLotsByIDsLib,
  getTypesenseLots as getTypesenseLotsLib,
  typesenseClient,
  useGetTypesenseAuctionsByIDs as useGetTypesenseAuctionsByIDsLib,
  useGetTypesenseAuctions as useGetTypesenseAuctionsLib,
  useGetTypesenseHeadlines as useGetTypesenseHeadlinesLib,
  useGetTypesenseLotsByIDs as useGetTypesenseLotsByIDsLib,
  useGetTypesenseLots as useGetTypesenseLotsLib,
} from '@nx/typesense'

import { auctionLotsPerPage, config, configHeadline } from './getConfig'

const client = typesenseClient(config)
const clientHeadline = typesenseClient(configHeadline)

/**
 * AUCTIONS
 */
/* istanbul ignore next */
export const getTypesenseAuctions = getTypesenseAuctionsLib(client)
/* istanbul ignore next */
export const getTypesenseAuctionsByIDs = (ids: string[] | number[]) =>
  getTypesenseAuctionsByIDsLib(client, ids)
/* istanbul ignore next */
export const useGetTypesenseAuctionsByIDs = (
  args: Omit<Parameters<typeof useGetTypesenseAuctionsByIDsLib>[0], 'client'>
) => useGetTypesenseAuctionsByIDsLib({ client, ...args })
/* istanbul ignore next */
export const useGetTypesenseAuctions = (
  args: Omit<Parameters<typeof useGetTypesenseAuctionsLib>[0], 'client'>
) => useGetTypesenseAuctionsLib({ client, ...args })
/* istanbul ignore next */
export const getTypesenseAuctionDetails = getTypesenseAuctionDetailsLib(client)

/**
 * LOTS
 */
/* istanbul ignore next */
export const getTypesenseLotDetails = getTypesenseLotDetailsLib(client)
/* istanbul ignore next */
export const getTypesenseLots = getTypesenseLotsLib(client)
/* istanbul ignore next */
export const useGetTypesenseLots = (
  args: Omit<
    Parameters<typeof useGetTypesenseLotsLib>[0],
    'client' | 'auctionLotsPerPage'
  >
) => useGetTypesenseLotsLib({ client, auctionLotsPerPage, ...args })
/* istanbul ignore next */
export const getTypesenseLotsByIDs = (ids: string[] | number[]) =>
  getTypesenseLotsByIDsLib(client, ids)
export const useGetTypesenseLotsByIDs = (
  args: Omit<Parameters<typeof useGetTypesenseLotsByIDsLib>[0], 'client'>
) => useGetTypesenseLotsByIDsLib({ client, ...args })

/**
 * HEADLINES
 */
/* istanbul ignore next */
export const getTypesenseHeadlines = getTypesenseHeadlinesLib(clientHeadline)
/* istanbul ignore next */
export const getTypesenseHeadlinesByIDs = (ids: string[] | number[]) =>
  getTypesenseHeadlinesByIDsLib(clientHeadline, ids)
/* istanbul ignore next */
export const useGetTypesenseHeadlines = (
  args: Omit<Parameters<typeof useGetTypesenseHeadlinesLib>[0], 'client'>
) => useGetTypesenseHeadlinesLib({ client: clientHeadline, ...args })
